import { render, staticRenderFns } from "./ChangeItem.vue?vue&type=template&id=6d1e388f&scoped=true"
import script from "./ChangeItem.vue?vue&type=script&lang=js"
export * from "./ChangeItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1e388f",
  null
  
)

export default component.exports